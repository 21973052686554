import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link
} from "react-router-dom";
import styled from "styled-components";

import { Panda, getPandasForAddress } from "../utils/getSnapshot";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { Header, ButtonIcon } from '../components'

import {
  shortenAddress,
} from "../utils/candyMachine";

const ConnectButton = styled(WalletDialogButton)``;

interface ArcadeProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
}

export const Arcade = (props: ArcadeProps) => {
  const [pandas, setPandas] = useState<Panda[]>();

  const wallet = useAnchorWallet();

  useEffect(() => {
    (async () => {
      if (wallet) {
        const pandas = await getPandasForAddress(props.connection, wallet.publicKey);
        
        if (pandas) setPandas(pandas);
      }
    })();
  }, [wallet, props.connection]);


  return (
    <div className="page-arcade">
      <Header>
        {!wallet ? (
          <ConnectButton>
            <>
              <ButtonIcon color="white" />
              Connect Wallet
            </>
          </ConnectButton>
        ) : (
          <div className="button dark">
            <ButtonIcon color="white" />
            {shortenAddress(wallet.publicKey?.toBase58() || "")}
          </div>
        )}
      </Header>
      <div className="hero section centered">
        <div className="container">
          <h1 className="hero-heading">NGMI Arcade</h1>
          <p className="hero-subheading">
            Play arcade games using your NGMI Panda. Must have at least one panda to play.
          </p>
          
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="arcade">
            <ArcadeItem slug="breakout" name="Battle Cranes!" image="battle-crane-poster.png" />
            <ArcadeItem slug="craneDodge" name="Crane Dodge!" image="battle-cranes-two-poster.png" />
            <ArcadeItem slug="halo" name="Halo 7!" image="halo.png" />
            <ArcadeItem slug="battleCrates" name="Battle Crates!" image="battle-crates-poster.png" />
          </div>
        </div>
      </div>
    </div>
  )
}

interface ArcadeItemProps {
  image: string;
  name: string;
  slug: string;
}

const ArcadeItem = (props: ArcadeItemProps) => {
  const { image, name, slug } = props;

  return (
    <Link className="arcade-item" to={`/arcade/${slug}`}>
      <img className="arcade-item-image" src={`/images/arcade/${image}`} />
      <div className="arcade-item-meta">
        {/* <div className="arcade-item-name">
          {name}
        </div> */}
      </div>
    </Link>
  )
}