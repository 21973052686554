export interface MintingProps {
  children: JSX.Element[] | JSX.Element;
}

export const Minting = (props: MintingProps) => {
  return (
    <div className="minting-section">
      <div className="container">
        <div className="minting-flex">
          {/* <div className="minting-image-box"> */}
            <img className="minting-image" src="/images/gm.png" />
          {/* </div> */}
          <div className="minting-box">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}