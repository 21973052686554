import { useState } from "react";

import { Rarity } from '.';

export const TraitsContent = () =>  {
  const [activeRarity, setActiveRarity] = useState<number>(0);

  return (
    <div id="traits" className="section">
      <div className="container">
        <div className="section-header">
          <h1 className="section-heading">Traits</h1>
        </div>
        <div className="rarities-tabs">
          {CATEGORIES.map((category, i) => {
            return (
              <div className={`rarity-tab ${activeRarity == i && 'current'}`} onClick={() => setActiveRarity(i)}>
                {category}
              </div>
            )
          })}
        </div>

        <div className="rarities-box">
          {ATTRIBUTES.map((attribute, i) => {
            return (
              <>
              {TRAITS[activeRarity][i] && TRAITS[activeRarity][i].length > 0 && (
                <div className="rarity-category">
                  <h4 className="rarity-attribute-heading">{attribute}</h4>

                  <div className="rarities">
                    {TRAITS[activeRarity][i].map((trait, j) => {
                      const { image, name } = trait;
                      
                      return (
                        <Rarity
                          key={j}
                          image={image}
                          name={name}
                        />
                      )
                      })}
                  </div>
                </div>
              )}
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type Trait = {
  image: string; 
  name: string;
}

const CATEGORIES:String[] = ['Common', 'Rare', 'Superrare', 'Legendary'];
const ATTRIBUTES:String[] = ['Background', 'Eyes', 'Clothing', 'Jacket', 'Mouth', 'Glasses', 'Hat', 'Phone'];

const TRAITS:Trait[][][] = [ // All Traits
  [ // Category – Common
    [ // Attribute – Background
      {
        "image": "/images/rarities/background-blue-peach.png",
        "name": "Blue/Peach Gradient",
      },
      {
        "image": "/images/rarities/background-orange-green.png",
        "name": "Orange/Green Gradient",
      },
      {
        "image": "/images/rarities/background-green-blue.png",
        "name": "Green/Blue Gradient",
      },
      {
        "image": "/images/rarities/background-orange-red.png",
        "name": "Orange/Red Gradient",
      },
      {
        "image": "/images/rarities/background-teal-green.png",
        "name": "Teal/Green Gradient",
      },
      {
        "image": "/images/rarities/background-purple-violet.png",
        "name": "Purple/Violet Gradient",
      },
    ],
    [ // Attribute – Eyes
      {
        "image": "/images/rarities/eyes-blue.png",
        "name": "Blue Eyes",
      },
      {
        "image": "/images/rarities/eyes-brown.png",
        "name": "Brown Eyes",
      },
      {
        "image": "/images/rarities/eyes-orange.png",
        "name": "Orange Eyes",
      },
      
    ],
    [ // Attribute – Clothing
      {
        "image": "/images/rarities/clothing-tshirt-gm-purple.png",
        "name": "T-Shirt GM Blue",
      },
      {
        "image": "/images/rarities/clothing-tshirt-gm-green.png",
        "name": "T-Shirt GM Green",
      },
      {
        "image": "/images/rarities/clothing-tshirt-wife-blue.png",
        "name": "T-Shirt Wife Blue",
      },
      {
        "image": "/images/rarities/clothing-tshirt-wife-yellow.png",
        "name": "T-Shirt Wife Yellow",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-green.png",
        "name": "T-Shirt Fast Food Green",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-peach.png",
        "name": "T-Shirt Fast Food Peach",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-purple.png",
        "name": "T-Shirt Fast Food Purple",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-white.png",
        "name": "T-Shirt Fast Food White",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-blue.png",
        "name": "T-Shirt Fast Food Blue",
      },
      {
        "image": "/images/rarities/clothing-tshirt-trader.png",
        "name": "T-Shirt Wash Trader",
      },
      {
        "image": "/images/rarities/clothing-tshirt-ngmi-yellow.png",
        "name": "T-Shirt NGMI Yellow",
      },
      {
        "image": "/images/rarities/clothing-tshirt-ngmi-peach.png",
        "name": "T-Shirt NGMI Peach",
      },
      {
        "image": "/images/rarities/clothing-tshirt-fast-white.png",
        "name": "T-Shirt Fast Food White",
      },
      {
        "image": "/images/rarities/clothing-tshirt-oooof-peach.png",
        "name": "T-Shirt Oooof Pink",
      },
      {
        "image": "/images/rarities/clothing-tshirt-ngmi-blue.png",
        "name": "T-Shirt NGMI Blue",
      },
      {
        "image": "/images/rarities/clothing-tshirt-ngmi-purple.png",
        "name": "T-Shirt NGMI Purple",
      },
      {
        "image": "/images/rarities/clothing-tshirt-pasta.png",
        "name": "T-Shirt Pasta White",
      },
    ],
    [ // Attribute – Jacket

    ],
    [ // Attribute – Mouth
      {
        "image": "/images/rarities/mouth-base.png",
        "name": "Base Mouth",
      },
      {
        "image": "/images/rarities/mouth-sad.png",
        "name": "Sad Mouth",
      },
    ],
    [ // Attribute – Glasses
      {
        "image": "/images/rarities/glasses-round-blue.png",
        "name": "Blue Round Glasses",
      },
      {
        "image": "/images/rarities/glasses-round-green.png",
        "name": "Green Round Glasses",
      },
      {
        "image": "/images/rarities/glasses-round-purple.png",
        "name": "Purple Round Glasses",
      },
      {
        "image": "/images/rarities/glasses-round-red.png",
        "name": "Red Round Glasses",
      },
      {
        "image": "/images/rarities/glasses-aviator-blue.png",
        "name": "Blue Aviator",
      },
      {
        "image": "/images/rarities/glasses-aviator-green.png",
        "name": "Green Aviator",
      },
      {
        "image": "/images/rarities/glasses-aviator-purple.png",
        "name": "Purple Aviator",
      },
      {
        "image": "/images/rarities/glasses-aviator-red.png",
        "name": "Red Aviator",
      },
    ],
    [ // Attribute – Hat
      {
        "image": "/images/rarities/hat-beanie-blue.png",
        "name": "Blue Beanie",
      },
      {
        "image": "/images/rarities/hat-beanie-peach.png",
        "name": "Peach Beanie",
      },
      {
        "image": "/images/rarities/hat-beanie-purple.png",
        "name": "Purple Beanie",
      },
      {
        "image": "/images/rarities/hat-beanie-aqua.png",
        "name": "Aqua Beanie",
      },
      {
        "image": "/images/rarities/hat-fedora-black.png",
        "name": "Black Fedora",
      },
      {
        "image": "/images/rarities/hat-fedora-blue.png",
        "name": "Blue Fedora",
      },
      {
        "image": "/images/rarities/hat-fedora-peach.png",
        "name": "Peach Fedora",
      },
      {
        "image": "/images/rarities/hat-fedora-purple.png",
        "name": "Purple Fedora",
      },
      {
        "image": "/images/rarities/hat-fedora-tan.png",
        "name": "Tan Fedora",
      },
      {
        "image": "/images/rarities/hat-peach.png",
        "name": "Peach",
      },
    ],
    [ // Attribute – Phone
      {
        "image": "/images/rarities/phone-red.png",
        "name": "Red Phone",
      },
      {
        "image": "/images/rarities/phone-silver.png",
        "name": "Silver Phone",
      },
      {
        "image": "/images/rarities/phone-purple.png",
        "name": "Purple Phone",
      },
    ],
  ],
  [ // Category – Rare
    [ // Attribute – Background
      {
        "image": "/images/rarities/background-silky-cool.png",
        "name": "Cool Silky Smooth",
      },
      {
        "image": "/images/rarities/background-silky-warm.png",
        "name": "Warm Silky Smooth",
      },
      
    ],
    [ // Attribute – Eyes
      {
        "image": "/images/rarities/eyes-green.png",
        "name": "Green Eyes",
      },
    ],
    [ // Attribute – Clothing
      {
        "image": "/images/rarities/clothing-tank-pain.png",
        "name": "Tank Top Max Pain",
      },
      {
        "image": "/images/rarities/clothing-tank-ngmi.png",
        "name": "Tank Top NGMI",
      },
      {
        "image": "/images/rarities/clothing-tank-tie-dye.png",
        "name": "Tank Top Tie Dye",
      },
      {
        "image": "/images/rarities/clothing-tank-stripe.png",
        "name": "Tank Top Stripe",
      },
      {
        "image": "/images/rarities/clothing-tshirt-trippy.png",
        "name": "T-Shirt Trippy",
      },
    ],
    [ // Attribute – Jacket
      {
        "image": "/images/rarities/jacket-black.png",
        "name": "Black Jacket",
      },
      {
        "image": "/images/rarities/jacket-purple.png",
        "name": "Purple Jacket",
      },
      {
        "image": "/images/rarities/jacket-white.png",
        "name": "White Jacket",
      },
    ],
    [ // Attribute – Mouth
      {
        "image": "/images/rarities/mouth-happy.png",
        "name": "Happy Mouth",
      },
      {
        "image": "/images/rarities/mouth-tucked.png",
        "name": "Tucked Mouth",
      },
      {
        "image": "/images/rarities/mouth-mask-purple.png",
        "name": "Purple Mask",
      },
      {
        "image": "/images/rarities/mouth-mask-aqua.png",
        "name": "Aqua Mask",
      },
      {
        "image": "/images/rarities/mouth-mask-black.png",
        "name": "Black Mask",
      },
      {
        "image": "/images/rarities/mouth-mask-red.png",
        "name": "Red Mask",
      },
    ],
    [ // Attribute – Glasses
      {
        "image": "/images/rarities/glasses-blocky-green.png",
        "name": "Green Blocky",
      },
      {
        "image": "/images/rarities/glasses-blocky-red.png",
        "name": "Red Blocky",
      },
    ],
    [ // Attribute – Hat
      {
        "image": "/images/rarities/hat-crown.png",
        "name": "Crown",
      },
    ],
    [ // Attribute – Phone
      {
        "image": "/images/rarities/phone-fuego.png",
        "name": "Fuego Phone",
      },
    ],
  ],
  [ // Category – Superrare
    [ // Attribute – Background
      
    ],
    [ // Attribute – Eyes
      {
        "image": "/images/rarities/eyes-baked.png",
        "name": "Baked Eyes",
      },
    ],
    [ // Attribute – Clothing
      {
        "image": "/images/rarities/clothing-polo-blue.png",
        "name": "Blue Polo",
      },
      {
        "image": "/images/rarities/clothing-polo-green.png",
        "name": "Green Polo",
      },
      {
        "image": "/images/rarities/clothing-polo-black.png",
        "name": "Black Polo",
      },
      {
        "image": "/images/rarities/clothing-tshirt-bitcoin.png",
        "name": "T-Shirt Bitcoin",
      },
      {
        "image": "/images/rarities/clothing-tshirt-doge.png",
        "name": "T-Shirt Doge",
      },
      {
        "image": "/images/rarities/clothing-tshirt-eth.png",
        "name": "T-Shirt ETH",
      },
      {
        "image": "/images/rarities/clothing-tshirt-solana.png",
        "name": "T-Shirt Solana",
      },
    ],
    [ // Attribute – Jacket
      
    ],
    [ // Attribute – Mouth
      {
        "image": "/images/rarities/mouth-mask-tie-warm.png",
        "name": "Tie Dye Warm Mask",
      },
      {
        "image": "/images/rarities/mouth-mask-rainbow.png",
        "name": "Rainbow Mask",
      },
    ],
    [ // Attribute – Glasses
      {
        "image": "/images/rarities/glasses-aviator-gold.png",
        "name": "Gold Aviator ",
      },
      {
        "image": "/images/rarities/glasses-oakley-blue.png",
        "name": "Blue Oakley",
      },
      {
        "image": "/images/rarities/glasses-oakley-red.png",
        "name": "Red Oakley ",
      },
    ],
    [ // Attribute – Hat
      {
        "image": "/images/rarities/hat-cowboy-candy.png",
        "name": "Candy Cowboy",
      },
      {
        "image": "/images/rarities/hat-beanie-rainbow.png",
        "name": "Rainbow Beanie",
      },
    ],
    [ // Attribute – Phone
      {
        "image": "/images/rarities/phone-coolio.png",
        "name": "Coolio Phone",
      },
      
    ],
  ],
  [ // Category – Legendary
    [ // Attribute – Background
      
    ],
    [ // Attribute – Eyes
      {
        "image": "/images/rarities/eyes-shitcoin.png",
        "name": "Shitcoin Eyes",
      },
    ],
    [ // Attribute – Clothing
      {
        "image": "/images/rarities/clothing-tank-fast.png",
        "name": "Tank Top Fast Food",
      },
      {
        "image": "/images/rarities/clothing-tank-rare.png",
        "name": "Tank Top Looks Rare",
      },
      {
        "image": "/images/rarities/clothing-tank-rug.png",
        "name": "Tank Top Rug",
      },
      {
        "image": "/images/rarities/clothing-tank-rainbow.png",
        "name": "Tank Top Rainbow",
      },
      {
        "image": "/images/rarities/clothing-polo-gold.png",
        "name": "Gold Polo",
      },
    ],
    [ // Attribute – Jacket

    ],
    [ // Attribute – Mouth
      {
        "image": "/images/rarities/mouth-mask-rug.png",
        "name": "Rug Mask",
      },
      {
        "image": "/images/rarities/mouth-mask-golden.png",
        "name": "Golden Mask",
      },
    ],
    [ // Attribute – Glasses
      {
        "image": "/images/rarities/glasses-3d.png",
        "name": "3D Glasses",
      },
      {
        "image": "/images/rarities/glasses-aviator-rainbow.png",
        "name": "Rainbow Aviator",
      },
      {
        "image": "/images/rarities/glasses-blocky-rainbow.png",
        "name": "Rainbow Blocky",
      },
      {
        "image": "/images/rarities/glasses-oakley-rainbow.png",
        "name": "Rainbow Oakley",
      },
      {
        "image": "/images/rarities/glasses-round-rainbow.png",
        "name": "Rainbow Round Glasses",
      },
      {
        "image": "/images/rarities/glasses-vr-up.png",
        "name": "VR Up Only",
      },
      {
        "image": "/images/rarities/glasses-vr-5.png",
        "name": "VR $5",
      },
    ],
    [ // Attribute – Hat
      {
        "image": "/images/rarities/hat-fast-black.png",
        "name": "Black Fast Food Cap",
      },
      {
        "image": "/images/rarities/hat-fast-red.png",
        "name": "Red Fast Food Cap",
      },
      {
        "image": "/images/rarities/hat-beanie-gold.png",
        "name": "Gold Beanie",
      },
    ],
    [ // Attribute – Phone
      {
        "image": "/images/rarities/phone-gold.png",
        "name": "Gold Phone",
      },
    ],
  ]
];