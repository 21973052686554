import { Header } from '../components';

export interface WhitepaperProps {

}

export const Whitepaper = (props: WhitepaperProps) => {

  return (
    <div className="page-whitepaper">
      <Header>
        <></>
      </Header>
      <div className="hero section centered">
        <div className="container">
          <h1 className="hero-heading">Whitepaper</h1>
          <div className="whitepaper">

          </div>
        </div>
      </div>
    </div>
  )
}