import { MusicNote } from "@material-ui/icons";
import Phaser from "phaser";
export function craneDodge(imageURL, solAddress, powerupName) {
    const config = {
        type: Phaser.AUTO,
        parent: 'phaser-target',
        width: 800,
        maxWidth: 800,
        height: 640,
        maxHeight: 640,
        scale: {
            autoCenter: Phaser.Scale.CENTER_BOTH,
            mode: Phaser.Scale.ScaleModes.FIT,
        },
        scene: {
            preload,
            create,
            update,
        },
        physics: {
            default: 'arcade',
            arcade: {
                gravity: true
            }
        }
    }

new Phaser.Game(config);

let player, cursors;

let playerMoveSpeed = 350;

let gameOver = false;

let gameStarted = false;

let openingText, gameOverText, scoreText, battleCranesText;

let bg;

const cranes = [];

const powerups = {
    freeze: {
        timeout: 10000
    },
    up_only: {
        timeout: 10000
    },
    shitcoin: {
        timeout: 5000
    },
    fast_food: {
        timeout: 5000
    },
    ngmi: {
        timeout: 10000
    },
    oakleys: {
        timeout: 10000
    }
}

function preload() {
    // bgs
    this.load.image('bg0', '/assets/images/crane-bg.jpg');

    // bricks
    this.load.image('crane1', '/assets/images/redcrane_32_32.png');
    this.load.image('crane2', '/assets/images/cranejuice.png');
    this.load.image('crane3', '/assets/images/jellyfish.png');

    this.load.image('shit', '/assets/images/shit.png');
    // laser/player
    this.load.image('panda', imageURL);

    // sfx
    this.load.audio("die1", "/assets/audio/die1.mp3");
    this.load.audio("die2", "/assets/audio/die2.mp3");
    this.load.audio("die3", "/assets/audio/die3.mp3");
    this.load.audio("die4", "/assets/audio/die4.mp3");
    this.load.audio("die5", "/assets/audio/die5.mp3");
    this.load.audio("die6", "/assets/audio/die6.mp3");

    this.load.audio("offline1", "/assets/audio/offline1.mp3");
    this.load.audio("offline2", "/assets/audio/offline2.mp3");
    this.load.audio("offline3", "/assets/audio/offline3.mp3");
    this.load.audio("offline4", "/assets/audio/offline4.mp3");
    this.load.audio("offline5", "/assets/audio/offline5.mp3");
    this.load.audio("offline6", "/assets/audio/offline6.mp3");

    this.load.audio("ready1", "/assets/audio/ready1.mp3");
    this.load.audio("ready2", "/assets/audio/ready2.mp3");
    this.load.audio("ready3", "/assets/audio/ready3.mp3");
    this.load.audio("ready4", "/assets/audio/ready4.mp3");
    this.load.audio("ready5", "/assets/audio/ready5.mp3");
    this.load.audio("ready6", "/assets/audio/ready6.mp3");

    // audio
    this.load.audio('cranejuice', "/assets/audio/video0.mp3");
}

let craneJuiceSong;

function create() {

    craneJuiceSong = this.sound.add("cranejuice");
    
    bg = this.add.image(0, 0, 'bg0');
    bg.setOrigin(0,0);
    player = this.physics.add.sprite(
        400, 600,
        'panda'
    )

    player.setScale(0.05);

    
    cursors = this.input.keyboard.createCursorKeys();

    player.setCollideWorldBounds(true);

    player.setImmovable(true);

    openingText = this.add.text(
        this.physics.world.bounds.width / 2,
        this.physics.world.bounds.height / 2,
        ['The object of the game is to stay alive the longest.',
         'Dodge the cranes.',
         'Press SPACE to Start.',
         'also: press C (the C is for Crane) to use your asset-derived powerup'
     ],
        {
          fontFamily: 'Monaco, Courier, monospace',
          fontSize: '15px',
          fill: '#fff'
        },
      );
    openingText.setOrigin(0.5);

    battleCranesText = this.add.text(
        this.physics.world.bounds.width / 2,
       30,
        'Battle Cranes',
        {
            fontFamily: 'Monaco, Courier, monospace',
            fontSize: '50px',
            fill: '#fff'
          },
    )
    battleCranesText.setOrigin(0.5);

    scoreText = this.add.text(
        this.physics.world.bounds.width - 150,
       25,
        "Score: " + score,
        {
            fontFamily: 'Monaco, Courier, monospace',
            fontSize: '20px',
            fill: '#fff'
          },
    )

    scoreText.setOrigin(0.5);
    scoreText.setVisible(false);

    gameOverText = this.add.text(
        this.physics.world.bounds.width / 2,
        this.physics.world.bounds.height / 2,
        'Ya lost! Press space to start again',
        {
          fontFamily: 'Monaco, Courier, monospace',
          fontSize: '25px',
          fill: '#fff'
        },
      );

    gameOverText.setOrigin(0.5);

    // Make it invisible until the player loses
    gameOverText.setVisible(false);
}

function update() {
    if(isGameOver()) {
        gameOverText.setVisible(true, true);
        clearInterval(intervalID);
        if(cursors.space.isDown) {
            window.location.reload()
        }
    } else {
        handleCursors(this.sound, this.physics, this.input, this.add);
    }
}

let score = 0;
let powerupAvailable = true;
let powerupText;

// die
let maxPainUsed = false;
let invulnerable = false;
function hitPlayer() {
    if(powerupName === "max_pain" && !maxPainUsed) {
        maxPainUsed = true;
        invulnerable = true;
        setTimeout(() => {
            invulnerable = false;
        }, 4 * 1000);
        powerupText.setText("POWERUP OFFLINE");
    } else if(!invulnerable) {
        gameOver = true;
        clearInterval(intervalID);
    }
}
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function handlePowerupByName(name, sound) {
      if(gameOver || !gameStarted) {
          return
      }

      if(powerupName == "generic" || powerupName == "max_pain") {
          return;
      }

      if(!powerupAvailable) {
        craneJuiceSong.pause();
            setTimeout(() => {
                craneJuiceSong.resume();
            }, 3 * 1000);
            sound.play("offline"+randomIntFromInterval(1, 6));
            return;
      }

    powerupAvailable = false;
    powerupText.setText("POWERUP OFFLINE");
    
    craneJuiceSong.pause();
    setTimeout(() => {
        craneJuiceSong.resume();
    }, 3 * 1000);
    sound.play("die"+randomIntFromInterval(1, 6));
    
      switch(name) {
        case "freeze": {
            cranes.map(crane => {
                const originalVelocityY = crane.body.velocity.y;
                crane.setVelocityX(0);
                crane.setVelocityY(0);
                setTimeout(() => {
                    crane.setVelocityY(originalVelocityY);
                }, randomIntFromInterval(1000, 5000))
            });
        }
        break;
        case "up_only":
            cranes.map(crane => {
                const originalVelocityY = crane.body.velocity.y;
                crane.setVelocityX(0);
                crane.setVelocityY(-originalVelocityY);
                setTimeout(() => {
                    crane.setVelocityY(originalVelocityY);
                }, randomIntFromInterval(1000, 5000))
            })
            break;
        case "shitcoin":
            cranes.map(crane => {
                crane.setTexture("shit");
                const originalVelocityY = crane.body.velocity.y;
                crane.setVelocityX(0);
                crane.setVelocityY(100);
                setTimeout(() => {
                    crane.setVelocityY(originalVelocityY);
                }, randomIntFromInterval(1000, 5000))
            })
            break;
        case "fast_food":
            playerMoveSpeed = playerMoveSpeed * 2;
            setTimeout(() => {
                playerMoveSpeed = playerMoveSpeed / 2;
            }, randomIntFromInterval(1000, 5000));
            break;
        case "ngmi":
            score += 30;
            scoreText.setText("Score: " + score);
            gameOverText.setVisible(true, true);
            clearInterval(intervalID);
            if(cursors.space.isDown) {
                window.location.reload()
            }
            break;
        case "oakleys":
            window.open('mailto:nftspike@gmail.com?subject=Oakleys&body=IHopeThisLetterFindsYouWell.IHateTheOakleys.Welp,SeeYa');
            break;
      }

      setTimeout(() => {
          powerupAvailable = true;
          powerupText.setText("POWERUP READY:"  + powerupName);
          craneJuiceSong.pause();
          setTimeout(() => {
              craneJuiceSong.resume();
          }, 3 * 1000);
          sound.play("ready"+randomIntFromInterval(1, 6));
      }, powerups[name].timeout)
  }

  let intervalID;
function handleCursors(sound, physics, input, add) {
    if (cursors.space.isDown) {
        if(!gameStarted) {
            gameStarted = true;
            powerupText = add.text(
                70,
                30,
                "POWERUP READY " + powerupName,
                {
                    fontFamily: 'Monaco, Courier, monospace',
                    fontSize: '20px',
                    fill: '#fff'
                  },
            )
            craneJuiceSong.play();
            setInterval(() => {
                craneJuiceSong.play();
            }, 60 * 1000)
            scoreText.setVisible(true);
            battleCranesText.setVisible(false);
            openingText.setVisible(false);

            player.body.gravity.y = 150;
            setInterval(() => {
                player.body.gravity.y += 50;
            }, 5 * 1000)            

            function spawnCranes() {
                const cranePick ="crane"+randomIntFromInterval(1, 3)
                const crane = physics.add.sprite(randomIntFromInterval(0, 800), 0, cranePick);
                if(cranePick === "crane1") {
                    crane.setScale(randomIntFromInterval(1, 2));
                } else {
                    crane.setScale((Math.random() * (0.1 - 0.5) + 0.5).toFixed(4))
                }
                
                physics.add.collider(crane, player, hitPlayer, null, this);
                crane.setVelocityY(randomIntFromInterval(100, 500));
                cranes.push(crane);
                setTimeout(spawnCranes, randomIntFromInterval(10, 390));
            }

            spawnCranes();

            input.keyboard.on("keydown-C", (event) => {
                handlePowerupByName(powerupName, sound);
            });

            intervalID = setInterval(() => {
                score++;
                scoreText.setText("Score: " + score);
            }, 1000)

            
        }
      }

    if (cursors.left.isDown) {
        player.body.setVelocityX(-playerMoveSpeed);
    } else if (cursors.right.isDown) {
        player.body.setVelocityX(playerMoveSpeed);
    } else if(cursors.up.isDown) {
        player.body.setVelocityY(-playerMoveSpeed);
    } else if(cursors.down.isDown) {
        player.body.setVelocityY(playerMoveSpeed);
    } else {
        player.body.setVelocityX(0);
        player.body.setVelocityY(0);
    }
    
}

function isGameOver() {
    return gameOver;
}
}