import { Header, TraitsContent } from "../components";

interface TraitsProps {

}

export const Traits = (props: TraitsProps) => {

  return (
    <div className="section">
      <Header>
        <></>
      </Header>
      <TraitsContent />
    </div>
    
  )
}