export const Team = () =>  {
  return (
    <div id="team" className="section">
      <div className="container">
        <div className="header-content">
          <h2 className="section-heading">Team</h2>
        </div>
        <div className="members">
          <Member
            image="/images/spike.jpg"
            job="Design &amp; Art"
            twitter="nftspike"
            bio="Baller NFT artist with 7 years of Product Design experience."
          />
          <Member
            image="/images/cyberhorsey.jpg?v=2"
            job="Contract Development"
            twitter="Cyberhorsey"
            bio="Blockchain developer with 5 years of experience."
          />
          <Member
            image="/images/solana-king-glasses.jpg"
            job="Development &amp; Community"
            twitter="nealogrady"
            bio="Full-stack programmer with 8 years experience. CTO of two companies."
          />
        </div>
        <div className="button-row centered">
          <a href="https://discord.gg/Gyd9GNwNxh" target="_blank">
            <button className="button dark">
              Meet them in Discord
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export interface MemberProps {
  image: string;
  job: String;
  twitter: string;
  bio: String;
}

const Member = (props: MemberProps) => {
  const { image, job, twitter, bio } = props;

  return (
    <a href={`https://twitter.com/${twitter}`} className="member" target="_blank">
      <img src={image} className="member-image" />
      <div className="member-name">@{twitter}</div>
      <div className="member-job">{job}</div>
      <div className="member-bio">
        {bio}
      </div>
    </a>
  )
}
