import { FaqItem } from '.';

export const FaqSection = () => {
  return (
    <div id="faq" className="section">
      <div className="container">
        <div className="section-header ">
          <h2 className="section-heading">FAQ</h2>
        </div>

        <div className="faq-items">
          <FaqItem question="Wen mint?">
            <p>We will be doing the drop October 17th at 20:00 UTC. Be there or have fun not making it...noob.</p>
          </FaqItem>
          <FaqItem question="How many Pandas can I buy at once?">
            <p>You can mint from 1 to 9 pandas in one transactions. It will mint one at a time until all of your pandas are minted, or until it sells out.</p>
          </FaqItem>
          <FaqItem question="Is there a limit per wallet?">
            <p>No, there isn't. First-come first-serve, one panda per mint.</p>
          </FaqItem>
          <FaqItem question="What wallet do I need?">
            <p>You must use either one of these Solana wallets to mint a panda; Phantom, SolFlare, Sollet, or Slope. If you don't have one of these, wtf is wrong with you noob, go set one up.</p>
            <p>We recommend Phantom. Here's a video on how to set up a Phantom wallet: https://www.youtube.com/watch?v=4vzgPiLrpAE</p>
          </FaqItem>
          <FaqItem question="How can I see my NFT?">
            <p>With your eyes dumbass smfh</p>
            <p>You can also see your NGMI Panda on Digital Eyes and Solanart (after they're listed there), or in your wallet (we recommend Phantom or SolFlare). Click on the NFT tab in either wallet and you'll see your panda!</p>
          </FaqItem>
          <FaqItem question="Where can I buy or sell NGMI Pandas after the drop?">
            <p>We will list the pandas on secondary markets Digital Eyes and other Solana NFT marketplaces shortly after launch.</p>
          </FaqItem>
          <FaqItem question="I pay SOL, no mint!! Where panda!??">
            <p>After your transaction goes through, it may take a few minutes for your panda to minted and appear in your wallet. If SOL gets subtracted for your wallet that means you bought a panda and it will arrive in your wallet shortly.</p>
          </FaqItem>
          <FaqItem question="Who made these?">
            <p>We're a team of 1 designer and 2 devs with years of crypto experience. <a href="#team">Learn more about the team below</a>.</p>
          </FaqItem>
        </div>
      </div>
    </div>
  )
}
