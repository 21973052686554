import React, { useEffect, useState } from 'react';

export interface HeroProps {

}

export const Hero = (props: HeroProps) => {
  const IMAGES = ['up-only.jpg', '420.jpg', 'GMI.jpg', '69.jpg', 'goldie.jpg', 'rugmaster.jpg', 'baller.jpg', 'fast-shitcoiner.jpg', 'ngmi.jpg', '5-dolla.jpg', 'ice-cold.jpg', 'red-days.jpg', 'green-days.jpg', 'berry-niave.jpg', 'peachy.jpg', 'GMI-gold.jpg', 'probably-nothing.jpg', 'trendster.jpg'];
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

  useEffect(() => {
    setTimeout(()=>{
      let newActiveIndex = activeImageIndex === IMAGES.length-1 ? 0 : activeImageIndex+1     
      setActiveImageIndex(newActiveIndex);
    }, 3000);
  }, [activeImageIndex])

  return (
    <div className="hero section">
      <div className="container">
        <div className="hero-flex">
          <div className="hero-image-box">
            <img className="hero-image" src={`/images/special/${IMAGES[activeImageIndex]}`} />
          </div>
          <div className="hero-copy-box">
            <h1 className="hero-heading max-width">Not Gonna Make It</h1>
            <p className="hero-text">
              A group of <strong>8,880 degen pandas</strong> that are Not Gonna Make It. Their constant habit of longing tops and panic selling bottoms makes them a perfect candidate for the fast food industry.
            </p>
            <p>
              Hidden amongst the 8,888 are 8 pandas who are GMI.
            </p>
            <div className="">
              <a href="https://discord.gg/ngmi" target="_blank">
                <button className="button dark frame">
                  Join Discord
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
