import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import styled from "styled-components";

import { Panda, getPandasForAddress } from "../../utils/getSnapshot";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { battleCranes } from "../../utils/battleCranes";

import { Header, ButtonIcon } from '../../components'

import {
  shortenAddress,
} from "../../utils/candyMachine";
import { sleep } from "../../utils";

const ConnectButton = styled(WalletDialogButton)``;

interface BreakoutProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
}

const Breakout = (props: BreakoutProps) => {
  const [pandas, setPandas] = useState<Panda[]>();
  const [chosenPanda, setChosenPanda] = useState<Panda>();
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const wallet = useAnchorWallet();

  const start = function(panda: Panda) {
    setChosenPanda(panda);
    setGameStarted(true);
    sleep(5000);
    battleCranes(panda.image, wallet?.publicKey)
  }
  
  useEffect(() => {
    (async () => {
  })();
}, [wallet, props.connection, chosenPanda]);

  useEffect(() => {
    (async () => {
      if (wallet) {
        const pandas = await getPandasForAddress(props.connection, wallet.publicKey);
        setPandas(pandas);
      }
    })();
  }, [wallet, props.connection]);

  return (
    <div className="page-arcade">
      <Header>
        {!wallet ? (
          <ConnectButton>
            <>
              <ButtonIcon color="white" />
              Connect Wallet
            </>
          </ConnectButton>
        ) : (
          <div className="button dark">
            <ButtonIcon color="white" />
            {shortenAddress(wallet.publicKey?.toBase58() || "")}
          </div>
        )}
      </Header>
      <div className="hero section centered">
        <div className="container">
          <h1 className="hero-heading">NGMI Battle Cranes</h1>
          <div id="phaser-target"></div>
          {!wallet ? (
            <>
              <p>Please connect to play.</p>
              <ConnectButton>
                <>
                  <ButtonIcon color="black" />
                  Connect Wallet
                </>
              </ConnectButton>
            </>
          ) : (
            <>
              {pandas && pandas.length > 0 ? (
                <>
                  {chosenPanda ? (
                    <>
                      <div 
                        className="marketplace-item panda"
                      >
                        {!gameStarted ? (
                          <>
                            <p>Chosen Panda:</p>
                            <img src={chosenPanda.image} className="marketplace-item-image" />
                            <p>{chosenPanda.name}</p>
                          </>
                        ): (
                          <>
                          </>
                        )}
                      </div>
                    
                    </>
                  ): (
                    <>
                      <p>Choose which panda you want to play with:</p>
                      <div className="marketplace-items panda-chooser">
                        {pandas.map((panda, i) => {
                          return (
                            <div 
                              className="marketplace-item panda"
                              onClick={() => start(panda)}
                              key={i}
                            >
                              <img src={panda.image} className="marketplace-item-image panda-chooser-image" />
                              <p>{panda.name}</p>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                  
                </>
              ) : (
                <p className="">
                  You must own at least one panda to play!
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Breakout;