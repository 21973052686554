import { MusicNote } from "@material-ui/icons";
import Phaser from "phaser";
export function halo(imageURL, solAddress, powerupName) {
    // i went to the phaser documentation, and it said make an obect like this.
    // so I did.
    const config = {
        type: Phaser.AUTO,
        parent: 'phaser-target',
        width: 800,
        maxWidth: 800,
        height: 640,
        maxHeight: 640,
        scale: {
            autoCenter: Phaser.Scale.CENTER_BOTH,
            mode: Phaser.Scale.ScaleModes.FIT,
        },
        scene: {
            preload,
            create,
            update,
        },
        physics: {
            default: 'arcade',
            arcade: {
                gravity: true
            }
        }
    }

new Phaser.Game(config);

let player, cursors;

let playerMoveSpeed = 350;

let gameOver = false;

let gameStarted = false;

let openingText, gameOverText, scoreText, battleCranesText;

let bg;

const cranes = [];

const powerups = {
    freeze: {
        timeout: 10000
    },
    up_only: {
        timeout: 10000
    },
    shitcoin: {
        timeout: 5000
    },
    fast_food: {
        timeout: 5000
    },
    ngmi: {
        timeout: 10000
    },
    oakleys: {
        timeout: 10000
    }
}

function preload() {
    // bgs
    this.load.image('bg0', '/assets/images/hrn2.png');

    // bricks
    this.load.image('crane1', '/assets/images/redcrane_32_32.png');
    this.load.image('crane2', '/assets/images/cranejuice.png');
    this.load.image('crane3', '/assets/images/jellyfish.png');

    this.load.image('shit', '/assets/images/shit.png');
    // laser/player
    this.load.image('panda', imageURL);
    this.load.image('masterchief', '/assets/images/masterchief.png');

    // sfx
    this.load.audio("fart", "/assets/audio/fart-01.mp3");
    this.load.audio("fuck", "/assets/audio/fuck.mp3");


    // audio
    this.load.audio('cranejuice', "/assets/audio/lawyer_crow.mp3");
}

let craneJuiceSong;

let masterChief;
function create() {

    craneJuiceSong = this.sound.add("cranejuice");
    
    bg = this.add.image(0, 0, 'bg0');
    bg.setOrigin(0,0);
    bg.setAlpha(0.3);
    player = this.physics.add.sprite(
        200, 350,
        'panda'
    )

    masterChief = this.physics.add.sprite(700, 320, 'masterchief');

    player.setScale(0.05);

    
    cursors = this.input.keyboard.createCursorKeys();

    player.setCollideWorldBounds(true);

    player.setImmovable(true);

    openingText = this.add.text(
        this.physics.world.bounds.width / 2,
        this.physics.world.bounds.height / 2,
        [' Halo Guy and little Halo Guy have gone rogue, now its up to you to stop them',
        ' they are shooting cranes at you, shits fucked, its up to u now.',
        'The object of the game is to defeat the Halo Guy and little halo guy as well',
        'dont forget about little halo guy',
         'Press SPACE to Start.',
         'also: press C (the C is for Crane) to shoot your crane juice at the Halo Guy team',
         'you only have crane juice if your GMI otherwise you have no bullets and you cant win',
         'but you know, still press c',
         'yes this satisfies the only-GMI game i promised THread so i fuckin deliver'
     ],
        {
          fontFamily: 'Monaco, Courier, monospace',
          fontSize: '15px',
          fill: '#fff'
        },
      );
    openingText.setOrigin(0.5);
    masterChief.setImmovable(true);

    this.physics.add.collider(player, masterChief, function() {
        gameOver = true;
    }, null, this);

    battleCranesText = this.add.text(
        this.physics.world.bounds.width / 2,
       30,
        'Battle Cranes',
        {
            fontFamily: 'Monaco, Courier, monospace',
            fontSize: '50px',
            fill: '#fff'
          },
    )
    battleCranesText.setOrigin(0.5);

    scoreText = this.add.text(
        this.physics.world.bounds.width - 150,
       25,
        "Halo Guy HP:" + masterChiefHP,
        {
            fontFamily: 'Monaco, Courier, monospace',
            fontSize: '20px',
            fill: '#fff'
          },
    )

    scoreText.setOrigin(0.5);
    scoreText.setVisible(false);

    gameOverText = this.add.text(
        this.physics.world.bounds.width / 2,
        this.physics.world.bounds.height / 2,
        'Ya lost! Press space to start again',
        {
          fontFamily: 'Monaco, Courier, monospace',
          fontSize: '25px',
          fill: '#fff'
        },
      );

    gameOverText.setOrigin(0.5);

    // Make it invisible until the player loses
    gameOverText.setVisible(false);
}

function update() {
    if(isGameOver()) {
        gameOverText.setVisible(true, true);
        clearInterval(intervalID);
        if(cursors.space.isDown) {
            window.location.reload()
        }
    } else {
        handleCursors(this.sound, this.physics, this.input, this.add);
    }
}

function hitPlayer() {
    gameOver = true;
}
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function handlePowerupByName(name, sound, physics) {
      if(gameOver || !gameStarted) {
          return
      }
    
      switch(name) {
        case "generic": 
            let bullet = physics.add.sprite(player.x + 50, player.y, "crane2")
            bullet.setScale(0.2);
            bullet.setVelocityX(100);
            physics.add.collider(bullet, masterChief, function() {
            
                hitMasterChief(sound);
                bullet.destroy(true);
            }, null, this);
            break;
        
        case "gmi": 
            sound.play("fart");
            break;
        
      }
  }

  let masterChiefHP = 1000;

  function hitMasterChief(sound) {
      sound.play("fuck");
      if(masterChiefHP <= 0) {
          gameOver = true;
          return;
      }
      masterChiefHP--;
      scoreText.setText("Halo Guy HP:" + masterChiefHP)
  }

  let intervalID;
function handleCursors(sound, physics, input, add) {
    if (cursors.space.isDown) {
        if(!gameStarted) {
            gameStarted = true;
            craneJuiceSong.play();
            setInterval(() => {
                craneJuiceSong.play();
            }, 75 * 1000)
            scoreText.setVisible(true);
            battleCranesText.setVisible(false);
            openingText.setVisible(false);

            function spawnCranes() {
                const cranePick ="crane1"
                const crane = physics.add.sprite(
                    randomIntFromInterval(200, 800), 
                    randomIntFromInterval(0, 640),
                    cranePick,
                    );
                if(cranePick === "crane1") {
                    crane.setScale(randomIntFromInterval(1, 2));
                } else {
                    crane.setScale((Math.random() * (0.1 - 0.5) + 0.5).toFixed(4))
                }
                
                physics.add.collider(crane, player, hitPlayer, null, this);
                crane.setVelocityX(-randomIntFromInterval(100, 500));
                crane.setVelocityY(-randomIntFromInterval(100, 500))
                cranes.push(crane);
                setTimeout(spawnCranes, randomIntFromInterval(50, 350));
            }

            spawnCranes();

            input.keyboard.on("keydown-C", (event) => {
                handlePowerupByName(powerupName, sound, physics);
            });
            
        }
      }

      if(gameStarted) {
        if (cursors.left.isDown) {
            player.body.setVelocityX(-playerMoveSpeed);
        } else if (cursors.right.isDown) {
            player.body.setVelocityX(playerMoveSpeed);
        } else if(cursors.up.isDown) {
            player.body.setVelocityY(-playerMoveSpeed);
        } else if(cursors.down.isDown) {
            player.body.setVelocityY(playerMoveSpeed);
        } else {
            player.body.setVelocityX(0);
            player.body.setVelocityY(0);
        }
    }
    
}

function isGameOver() {
    return gameOver;
}
}