interface ButtonIconProps {
  color: string;
}
export const ButtonIcon = (props: ButtonIconProps) => {
  const { color } = props;
  
  return (
    <span className="button-icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" >
        <rect x="2" y="3" width="10" height="1" fill={color}></rect>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 5H2V13H14V5ZM11 10C11.5523 10 12 9.55228 12 9C12 8.44772 11.5523 8 11 8C10.4477 8 10 8.44772 10 9C10 9.55228 10.4477 10 11 10Z" fill={color}></path>
      </svg>
    </span>
  )
}