import { useEffect, useState } from "react";
import styled from "styled-components";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { battleCrates } from "../../utils/battleCrates";

import { Header, ButtonIcon } from '../../components'

import { ethers } from "ethers";

import {
  shortenAddress,
} from "../../utils/candyMachine";
import { sleep } from "../../utils";
import axios from "axios";

interface BattleCratesProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
}

declare global {
    interface Window {
        isGmeDefault: any;
        gme: any;
        ethereum: any;
    }
}

type Crate = {
    image: string;
    name: string;
}

const BattleCrates = (props: BattleCratesProps) => {
  const [pandas, setPandas] = useState<Crate[]>();
  const [chosenPanda, setChosenPanda] = useState<Crate>();
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [signer, setSigner] = useState<string>();


  const connect = async () => {
    await window.ethereum.enable()
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    setSigner(await signer.getAddress());
  }

  const start = function(crate: Crate) {
    setChosenPanda(crate);
    setGameStarted(true);
    sleep(5000);
    battleCrates("/assets/images/crate.png", "")
  }

  useEffect(() => {
    (async () => {
      if (signer) {
          const resp = await axios.get(`https://deep-index.moralis.io/api/v2/${encodeURIComponent(signer)}/nft?chain=eth&format=hex`, {
              headers: {
                  "X-API-KEY": "ceX08byGEAJ2qyneKPb55OFJVGsMZcUmAToCbXgZ0ml2zCfwvqyGQdNIT0Km6Yws"
              }
          });
          (resp.data.result || []).map(async (nft: any) => {
           if(nft.token_address.toLowerCase() === "0x391f64a5a9af0ed15c95fd17c33fe6de81986c47") {
            setPandas([{name: "A CRATE", image: "/images/crate.png"}]);
           }
          });
      }
    })();
  }, [signer]);

  return (
    <div className="page-arcade">
      <Header>
        {!signer ? (
         <div >

         </div>
        ) : (
          <div className="button dark">
            <ButtonIcon color="white" />
            {shortenAddress(signer)}
          </div>
        )}
      </Header>
      <div className="hero section centered">
        <div className="container">
          <h1 className="hero-heading">Battle CRATES</h1>
          <div id="phaser-target"></div>
          {!signer ? (
            <>
              <button onClick={async (e) => await connect()}> Connect Metamask</button>
            </>
          ) : (
            <>
              {pandas && pandas.length > 0 ? (
                <>
                  {chosenPanda ? (
                    <>
                      <div 
                        className="marketplace-item panda"
                      >
                        {!gameStarted ? (
                          <>
                            <p>Chosen CRATE:</p>
                            <img src={chosenPanda.image} className="marketplace-item-image" />
                            <p>A CRATE</p>
                          </>
                        ): (
                          <>
                          </>
                        )}
                      </div>
                    
                    </>
                  ): (
                    <>
                      <p>Choose which CRATE you want to play with:</p>
                      <div className="marketplace-items panda-chooser">
                        {pandas.map((panda, i) => {
                          return (
                            <div 
                              className="marketplace-item panda"
                              onClick={() => start(panda)}
                              key={i}
                            >
                              <img src={panda.image} className="marketplace-item-image panda-chooser-image" />
                              <p>{panda.name}</p>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                  
                </>
              ) : (
                <p className="">
                  You must own at least one CRATE to play!
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BattleCrates;