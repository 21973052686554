import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { Snapshot, getSnapshot } from "../utils/getSnapshot";

import { Header, ButtonIcon } from '../components'

import {
  shortenAddress,
} from "../utils/candyMachine";

import { attributes } from "../utils/attributes";

const ConnectButton = styled(WalletDialogButton)``;


export interface MarketplaceProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
}

export const Marketplace = (props: MarketplaceProps) => {
  const [snapshot, setSnapshot] = useState<Snapshot>();
  const [balance, setBalance] = useState<number>();

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();

  useEffect(() => {
    (async () => {
        const snapshot = await getSnapshot(props.connection);
        setSnapshot(snapshot);
        console.log(snapshot);
    })();
  }, [props.connection]);


  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  return (
    <main>
      <Header>
        {!wallet ? (
          <ConnectButton>
            <>
              <ButtonIcon color="white" />
              Connect Wallet
            </>
          </ConnectButton>
        ) : (
          <div className="button dark">
            <ButtonIcon color="white" />
            {shortenAddress(wallet.publicKey?.toBase58() || "")}
          </div>
        )}
      </Header>
      <div className="section">
        <div className="container">
          {/* {wallet && (
            <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
          )} */}

          {/*wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>*/}

          <div className="marketplace-box">
            <h2 className="section-heading">NGMI Pandas</h2>
            <div className="marketplace-items">
              {snapshot && snapshot.pandas.map(function(panda, i){
       
                
                return (
                  <div key={i} className="marketplace-item">
                    <img width="100" src={panda.image} className="marketplace-item-image"></img>
                    <div className="marketplace-attributes">
                      <div className="marketplace-attribute">
                        <span className="marketplace-attribute-type">Name:</span>
                        {panda.name}
                      </div>
                      <div className="marketplace-attribute">
                        <span className="marketplace-attribute-type">Owner:</span>
                        <a href={`https://explorer.solana.com/address/${panda.holder}`} target="_blank">{shortenAddress(panda.holder)}</a></div>
                      {panda.attributes.map((attribute, j) => {
                        return (
                          <div key={j} className="marketplace-attribute"> 
                            <span className="marketplace-attribute-type">{attribute.trait_type}:</span>
                             {attribute.value}
                          </div>
                        )
                      })}
                      
                    </div>
                    
                  </div>
                );
              })}
            </div>
          </div>
          
        </div>
      </div>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}