import "./App.css";
import { useMemo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import { Home, Roadmap, Whitepaper, Traits, Arcade, Marketplace } from './pages'
import { Footer } from './components';
import Breakout from './pages/arcade/Breakout'

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider, WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";
import CraneDodge from "./pages/arcade/CraneDodge";
import Halo from "./pages/arcade/Halo";
import BattleCrates from "./pages/arcade/BattleCrates";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 100000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
                
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
        MuiDialogTitle: {
          root: {
            background: '#000',
          }
        }
    },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <Router>
              <ScrollToTop />
              <main className="app">
                <Switch>
                  <Route path="/traits">
                    <Traits />
                  </Route>
                  <Route path="/roadmap">
                    <Roadmap />
                  </Route>
                  <Route path="/whitepaper">
                    <Whitepaper />
                  </Route>
                  <Route path="/marketplace">
                    <Marketplace
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/arcade/breakout">
                    <Breakout
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/arcade/battleCrates">
                    <BattleCrates
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/arcade/craneDodge">
                    <CraneDodge
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/arcade/halo">
                    <Halo
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/arcade">
                    <Arcade
                      connection={connection}
                      txTimeout={50000}
                    />
                  </Route>
                  <Route path="/">
                    <Home
                      candyMachineId={candyMachineId}
                      config={config}
                      connection={connection}
                      startDate={startDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
                  </Route>
                </Switch>
                <Footer />
              </main>
            </Router>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}