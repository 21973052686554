import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { gmiPandas } from "../utils/pandas";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  mintMultipleToken,
  shortenAddress,
} from "../utils/candyMachine";

import { Panda, getPandasForAddress } from "../utils/getSnapshot";

import { Header, ButtonIcon, Hero, Rarities, FaqSection, Team, Minting } from '../components';

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

// NEAL: use `mintMultipleToken` instead of `mintOneToken` when Mint Many is selected.
// we may need two different buttons. dunno if UI has that.
// but i tried it and it works to mint several. it takes a `amount` property at the end.
// it defaults to 5.
interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const MINT_PRICE = 0.42069;
const TOTAL_GMI = 8

export const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [mintAmount, setMintAmount] = useState<number>(1);

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [ngmiPandasSold, setNgmiPandasSold] = useState(0);
  const [gmiPandasSold, setGmiPandasSold] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      let ngmiPandasSold = itemsRedeemed;
      let gmiPandasSold = 0;
      switch (true) {
        case itemsRedeemed > gmiPandas[7]:
          gmiPandasSold = 8;
          break;
          case itemsRedeemed > gmiPandas[6]:
          gmiPandasSold = 7;
          break;
          case itemsRedeemed > gmiPandas[5]:
          gmiPandasSold = 6;
          break;
          case itemsRedeemed > gmiPandas[4]:
          gmiPandasSold = 5;
          break;
          case itemsRedeemed > gmiPandas[3]:
          gmiPandasSold = 4;
          break;
          case itemsRedeemed > gmiPandas[2]:
          gmiPandasSold = 3;
          break;
          case itemsRedeemed > gmiPandas[1]:
          gmiPandasSold = 2;
          break;
          case itemsRedeemed > gmiPandas[0]:
          gmiPandasSold = 1;
          break;
          default:
            gmiPandasSold = 0;
            break;
      }

      ngmiPandasSold = itemsRedeemed - gmiPandasSold;
      
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
      setGmiPandasSold(gmiPandasSold);
      setNgmiPandasSold(ngmiPandasSold);
    })();
  };

  const onMint = async () => {
    // if (mintAmount == 0) {
    //   onMintOne();
    // } else {
      onMintMultiple(mintAmount);
    // }
  }
  const onMintMultiple = async (amount: number) => {
    let count = 0;
    let didError = false;
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxIds = await mintMultipleToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury,
          amount
        );

        
       
                setAlertState({
                  open: true,
                  message: `Congratulations! ${amount} Pandas minted!`,
                  severity: "success",
                });
              }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || `Minting of Amount ${count} failed! Please try again!`;
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `Sold out!`;
          if (count > 1) message += ` Successfully minted ${count-1} Pandas`;
          setIsSoldOut(true);
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
          if (count > 1) message += ` Successfully minted ${count-1} Pandas`;
        }
      } else {
        if (error.code === 311) {
          message = `Sold out!`;
          if (count > 1) message += ` Successfully minted ${count-1} Pandas`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      if (!didError) {
        setAlertState({
          open: true,
          message,
          severity: "error",
        });

        didError = true;
      }
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      
      refreshCandyMachineState();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const onMintAmountChange = (mintAmount: number) => {
    if (mintAmount >= 1 && mintAmount <= 9 && itemsRemaining >= mintAmount) {
      setMintAmount(mintAmount);
    }
  }

  return (
    <div className="home">
      <Header>
        {!wallet ? (
          <ConnectButton>
            <>
              <ButtonIcon color="white" />
              Connect Wallet
            </>
          </ConnectButton>
        ) : (
          <div className="button dark">
            <ButtonIcon color="white" />
            {shortenAddress(wallet.publicKey?.toBase58() || "")}
          </div>
        )}
      </Header>
      <Hero />
      
      <Minting>
        <MintContainer>
          {!wallet ? (
            <div className="minting-unconnected">
              <ConnectButton>
                <>
                  <ButtonIcon color="black" />
                  Connect Wallet
                </>
              </ConnectButton>
            </div>
          ) : (
            <div className="minting-content">
              <div className="minting-quantity-box">
                <label className="minting-quantity-label">
                  Quantity
                </label>
                <div className="minting-quantity-input">
                  <div 
                    className={`minting-quantity-button ${mintAmount == 1 ? 'disabled' : ''}`} 
                    onClick={(e) => onMintAmountChange(mintAmount-1)}
                  >
                    <div className="minting-quantity-line"></div>
                  </div>
                  <div className="minting-quantity-number">{mintAmount}</div>
                  <div 
                    className={`minting-quantity-button ${(mintAmount == 9 || mintAmount >= itemsRemaining) ? 'disabled' : ''}`}
                    onClick={(e) => onMintAmountChange(mintAmount+1)}
                  >
                    <div className="minting-quantity-line"></div>
                    <div className="minting-quantity-line flipped"></div>
                  </div>
                </div>
              </div>
              
              <MintButton
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
                variant="contained"
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    `Mint ${mintAmount} for ${MINT_PRICE * mintAmount} SOL`
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => {
                      setIsActive(true)}
                    }
                    renderer={RenderCounter}
                  />
                )}
              </MintButton>
            </div>
          )}
        </MintContainer>
        {wallet && isActive ? (
          <div className="minting-progress-box">
            <div className="minting-progress-bar">
              <div className="minting-progress" style={{width: `${itemsRedeemed*100 / (itemsAvailable)}%`}}></div>
            </div>
            <div className="minting-meta">
              {itemsRemaining} Pandas remaining ({itemsRemaining - (TOTAL_GMI - gmiPandasSold)} NGMI + {TOTAL_GMI - gmiPandasSold} GMI)
            </div>
          </div>
        ) : <></>}
      </Minting>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

      <Rarities />
      <FaqSection />
      <Team />
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const RenderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  let dayWord = 'day';
  let hourWord = 'hour';
  let minuteWord = 'minute'
  let secondWord = 'second';

  if (days !== 1) dayWord += 's'
  if (hours !== 1) hourWord += 's'
  if (minutes !== 1) minuteWord += 's'
  if (seconds !== 1) secondWord += 's'
  return (
    <CounterText>
      {days} {dayWord}, {hours} {hourWord}, {minutes} {minuteWord}, {seconds} {secondWord}
      {/* {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds */}
    </CounterText>
  );
};
