interface RarityProps {
  image: string;
  name: string;
}

export const Rarity = (props: RarityProps) => {
  const { image, name } = props;
  return (
    <div className="rarity">
      <img className="rarity-image" src={image} />
      <div className="rarity-trait">
        {name}
      </div>
    </div>
  )
}