import {
  Link
} from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <nav className="footer-socials">
            <a className="footer-social" href="https://discord.gg/Gyd9GNwNxh" target="_blank">
              <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.428 2.88221C25.572 0.434362 21.054 0.0202614 20.862 0.00267256C20.5605 -0.0245072 20.274 0.157761 20.151 0.451949C20.142 0.471135 20.043 0.712562 19.9335 1.08829C21.822 1.42725 24.1425 2.11156 26.2425 3.50096C26.5785 3.7232 26.682 4.19486 26.4735 4.5546C26.3385 4.78803 26.103 4.91594 25.863 4.91594C25.734 4.91594 25.6035 4.87917 25.485 4.80083C21.876 2.41534 17.367 2.29542 16.5 2.29542C15.633 2.29542 11.1225 2.41534 7.5165 4.80083C7.179 5.02466 6.738 4.91274 6.528 4.5546C6.318 4.19486 6.423 3.7248 6.759 3.50096C8.859 2.11315 11.178 1.42725 13.068 1.08829C12.957 0.710963 12.858 0.471135 12.8505 0.451949C12.726 0.157759 12.441 -0.0277067 12.138 0.00426954C11.9475 0.0202588 7.4295 0.43436 4.5345 2.91738C3.0225 4.40591 0 13.1165 0 20.647C0 20.7797 0.0330007 20.9109 0.0945 21.026C2.181 24.932 7.872 25.9552 9.1695 26C9.177 26 9.1845 26 9.192 26C9.4215 26 9.6375 25.8833 9.7725 25.685L11.085 23.7632C7.5465 22.7879 5.739 21.1331 5.634 21.0356C5.337 20.7558 5.3085 20.2729 5.571 19.9563C5.8335 19.6398 6.285 19.6094 6.582 19.8876C6.6255 19.9292 9.954 22.9398 16.5 22.9398C23.058 22.9398 26.3865 19.9164 26.4195 19.886C26.7165 19.611 27.1695 19.6398 27.4305 19.9579C27.6915 20.2745 27.663 20.7558 27.3675 21.034C27.2625 21.1331 25.455 22.7863 21.9165 23.7616L23.229 25.6834C23.364 25.8817 23.58 25.9984 23.8095 25.9984C23.817 25.9984 23.8245 25.9984 23.832 25.9984C25.1295 25.9552 30.8205 24.932 32.907 21.0244C32.967 20.9093 33 20.7798 33 20.647C33 13.1165 29.9775 4.40591 28.428 2.88221L28.428 2.88221ZM11.8365 17.5884C10.4505 17.5884 9.3255 16.2182 9.3255 14.5298C9.3255 12.8415 10.449 11.4712 11.8365 11.4712C13.224 11.4712 14.3475 12.8415 14.3475 14.5298C14.3475 16.2182 13.224 17.5884 11.8365 17.5884ZM21.1635 17.5884C19.7775 17.5884 18.6525 16.2182 18.6525 14.5298C18.6525 12.8415 19.776 11.4712 21.1635 11.4712C22.5495 11.4712 23.6745 12.8415 23.6745 14.5298C23.6745 16.2182 22.5495 17.5884 21.1635 17.5884Z" fill="black"/>
              </svg>
            </a>
            <a className="footer-social" href="https://twitter.com/ngmipandas" target="_blank">
              <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 2.83173C26.9518 3.32211 25.8991 3.69231 24.7324 3.81731C25.8991 3.07692 26.8333 1.84615 27.2982 0.490385C26.1315 1.23077 24.9648 1.72115 23.6842 1.97115C22.5176 0.740385 21 0 19.3685 0C16.2148 0 13.6491 2.70673 13.6491 6.02885C13.6491 6.52404 13.6491 7.01442 13.7676 7.38461C8.98242 7.13942 4.78516 4.79808 1.98242 1.10577C1.39909 1.97115 1.16667 3.07692 1.16667 4.18269C1.16667 6.27885 2.21484 8.125 3.73242 9.23077C2.79818 9.10577 1.86849 8.86058 1.16667 8.49039C1.16667 8.49039 1.16667 8.49039 1.16667 8.61539C1.16667 11.5673 3.14909 14.0288 5.71484 14.524C5.25 14.6442 4.78516 14.7692 4.20182 14.7692C3.85091 14.7692 3.5 14.7692 3.14909 14.6442C3.85091 17.1058 5.95182 18.8317 8.51758 18.8317C6.53516 20.4327 4.08333 21.4135 1.39909 21.4135C0.934245 21.4135 0.464844 21.4135 0 21.2933C2.56576 23.0144 5.60091 24 8.75 24C19.3685 24 25.0833 14.7692 25.0833 6.76923C25.0833 6.52404 25.0833 6.27885 25.0833 6.02885C26.25 5.16827 27.1842 4.0625 28 2.83174" fill="black"/>
              </svg>
            </a>
            <a className="footer-social" href="https://t.me/joinchat/BI9cf9WxBlQwY2Jh" target="_blank">
              <img width="26" src="/images/telegram.png" />
            </a>
            <a className="footer-social" href="https://nftcalendar.io/" target="_blank">
              <img width="26" src="/images/nftcalendar.png" />
            </a>
          </nav>
          <nav className="footer-nav-links">
            <Link className="nav-link footer-nav-link" to="/">
              Home
            </Link>
            <Link className="nav-link footer-nav-link" to="/roadmap">
              Roadmap
            </Link>
            <Link className="nav-link footer-nav-link" to="/whitepaper">
              Whitepaper
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  )
}
