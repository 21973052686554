import {
  Link
} from "react-router-dom";

import { Rarity } from '.';

export const Rarities = () =>  {

  return (
    <div id="traits" className="section">
      <div className="container">
        <div className="section-header">
          <h1 className="section-heading">Traits</h1>
        </div>
        <p>Each panda is randomly generated with a variety of traits, including hat, eyes, mouth, shirt, phone, glasses, and background.</p>
        <div className="rarities-box">
          <div className="rarities margined">
            <Rarity image="/images/rarities/glasses-vr-up.png" name="VR Up Only" />
            <Rarity image="/images/rarities/eyes-shitcoin.png" name="Shitcoin Eyes" />
            <Rarity image="/images/rarities/clothing-tshirt-solana.png" name="Solana T-Shirt" />
            <Rarity image="/images/rarities/hat-cowboy-candy.png" name="Candy Cowboy" />
            <Rarity image="/images/rarities/clothing-tank-rug.png" name="Rug Tank Top" />
          </div>
        </div>
        <div className="button-row centered">
          <Link className="button dark" to="/traits">View all traits</Link>
        </div>
      </div>
    </div>
  )
}