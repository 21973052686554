import { useState }  from 'react';

export interface FaqProps {
  children: JSX.Element[] | JSX.Element,
  question: String
}

export const FaqItem = (props: FaqProps) => {
  const { question, children } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  let answerClasses = 'faq-answer';
  let lineClasses = 'faq-icon-line flipped top';

  if (isVisible) {
    answerClasses += ' visible';
    lineClasses = 'faq-icon-line top';
  }

  return (
    <div className="faq-item" onClick={() => setIsVisible(!isVisible)}>
      <div className="faq-header">
        <h6 className="faq-question">
          {question}
        </h6>
        <div className="faq-icon">
          <div className="faq-icon-line"></div>
          <div className={lineClasses}></div>
        </div>
      </div>
      <div className={answerClasses}>
        {children}
      </div>
    </div>
  )
}
