import { Header } from '../components';

interface RoadmapProps {

}

export const Roadmap = (props: RoadmapProps) => {

  return (
    <div className="page-roadmap">
      <Header>
        <></>
      </Header>
      <div className="hero section centered">
        <div className="container">
          <h1 className="hero-heading">Roadmap</h1>
          <div className="roadmap">
            <img src="https://images.unsplash.com/photo-1499062918700-389fa905494e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=928&q=80" />
          </div>
        </div>
      </div>
    </div>
  )
}